import React from "react";
import Footer from "../../components/footer/footer";
import Typewriter from "../../typewriter/typewriter";

import Hero from "../../components/hero/hero";
import "./Home.css";

const Home = () => (
  <div className="home-container">
    <main className="main-section">
      <h1 className="home-h1">
        <Typewriter text="kel404" delay={500} />
      </h1>
    </main>
    <Hero />
    <Footer />
  </div>
);

export default Home;
