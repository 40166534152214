import React from "react";
import Typewriter from "../../typewriter/typewriter";
import "./hero.css";

const Hero = () => (
  <div className="hero-container">
    <div className="hero-content">
      <p className="hero-paragraph">
        <Typewriter text="blockchain developer" delay={50} />
        <div></div>
        <Typewriter text="projects coming soon" delay={100} />
      </p>
    </div>
  </div>
);

export default Hero;
